import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import { Box } from '@primer/react';
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontContainer = makeShortcode("DoDontContainer");
const Do = makeShortcode("Do");
const Caption = makeShortcode("Caption");
const Dont = makeShortcode("Dont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h2>{`Anatomy`}</h2>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/2313998/226448730-9e4233ec-feff-4982-acce-9aa8ca5beac2.png",
        "alt": "Diagram of toggle switch with its parts labeled"
      }}></img></p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Track`}</strong>{`: The clickable area where the toggle switch moves back and forth`}</li>
      <li parentName="ul"><strong parentName="li">{`Knob`}</strong>{`: Changes color and position depending on whether the switch is on or off`}</li>
      <li parentName="ul"><strong parentName="li">{`Status`}</strong>{`: A text label showing whether the switch is on or off`}</li>
    </ul>
    <h2>{`Options`}</h2>
    <h3>{`Label and description`}</h3>
    <Box mb={5} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <div>
    <Box as="p" mt="0" mdxType="Box">
      Every toggle switch should have a label that says what is being turned on or off. Optionally, you may include a
      description about the setting that is being turned on or off.
    </Box>
    <Box as="p" mt="0" mdxType="Box">
      Labels should be succinct, and can describe an object or an action (for example: "Discussions" or "Automatically
      watch repositories"). Don't use phrases that describe the switch's state in your label.
    </Box>
  </div>
  <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/226448731-96a9bc7d-6c4f-4f30-8e32-ff73d0a76474.png" />
    </Box>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img role="presentation" src="https://user-images.githubusercontent.com/2313998/226448719-fd65a0ea-bbfb-4b53-a00d-2ca0db8f0991.png" />
    <Caption mdxType="Caption">Use succinct phrases with verbs or nouns</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img role="presentation" src="https://user-images.githubusercontent.com/2313998/226448723-86d146d8-1a92-4506-8a5e-106a8bc0b8a7.png" />
    <Caption mdxType="Caption">Don't use phrases that describe the switch's state</Caption>
  </Dont>
    </DoDontContainer>
    <h3>{`Layout`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <div>
    <Box as="p" mt="0" mdxType="Box">
      By default, lay out a toggle switch horizontally justified with its label and optional description.
    </Box>
    <Box as="p" mt="0" mdxType="Box">
      You may use a vertical layout when a toggle switch is layed out inline with other content, or if a horizontal
      layout would put the switch too far from its label. If the vertical layout is left-aligned, the "on"/"off" text
      may be moved to the right of the switch.
    </Box>
  </div>
  <Box display="flex" flexDirection="column" sx={{
        gap: 2,
        flexGrow: 1,
        flexShrink: 0
      }} mdxType="Box">
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/226448731-96a9bc7d-6c4f-4f30-8e32-ff73d0a76474.png" />
    <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/226448741-72a264f6-3269-4d09-8afe-75042a189a54.png" />
  </Box>
    </Box>
    <h3>{`Size`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="p" mt="0" mdxType="Box">
    A small variant is provided to maintain visual hierarchy, or for dense areas where the default size will not fit.
  </Box>
  <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/226448738-1dff6c1c-eac3-4a0a-850e-02d579272a11.png" />
    </Box>
    <h3>{`Loading`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="p" mt="0" mdxType="Box">
    When a toggle switch is waiting for something to load before toggling, a loading indicator should be displayed. The
    switch should not prematurely toggle from "on" to "off". Wait until loading is complete before showing a change.
  </Box>
  <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/226448735-1e9f8d24-a084-4cee-ae59-fbabce944d7a.png" />
    </Box>
    <h3>{`Progressive disclosure`}</h3>
    <Box mb={3} display="flex" alignItems="flex-start" flexDirection={['column', 'column', 'column', 'column', 'row']} sx={{
      gap: 3
    }} mdxType="Box">
  <Box as="p" mt="0" mdxType="Box">
    The <a href="/ui-patterns/progressive-disclosure">progressive disclosure pattern</a> may be used to hide or
    show content based on whether a toggle switch is on. Content revealed on toggle switch activation should always come{' '}
    <em>after</em> the toggle switch.
  </Box>
  <img width="456" role="presentation" src="https://user-images.githubusercontent.com/2313998/226448736-db898a3c-8b68-4e5e-a4e8-ad1f6d549062.png" />
    </Box>
    <h2>{`Mixed values`}</h2>
    <p>{`Since a toggle switch can only be "on" or "off", it could be confusing to use a switch to represent mixed values.`}</p>
    <p>{`If you have a group of configuration options that can be disabled, use a button instead of a toggle switch. Disabling the group turns off all configuration options, but remembers their previous state when the user wants to turn that section back on. While the section is disabled, the configuration options are hidden.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/2313998/226448737-ed98e831-ecbc-476e-809a-6799bf73a5f7.png",
        "alt": "Configuration options section that can be enabled or disabled. Configuration controls are hidden when it is disabled."
      }}></img></p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img role="presentation" src="https://user-images.githubusercontent.com/2313998/226448725-d7cb6db8-9738-4097-89f4-9279fec7400a.png" />
    <Caption mdxType="Caption">Use a button to affect multiple related toggles</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img role="presentation" src="https://user-images.githubusercontent.com/2313998/226448727-20d008fa-866b-4c6d-849a-81941e5d09eb.png" />
    <Caption mdxType="Caption">Don't use a toggle switch to represent mixed values</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Toggle switch vs checkbox`}</h2>
    <p>{`Toggle switches are buttons that change and save a boolean configuration option when they are toggled.
Checkboxes are form inputs that are used to select one or more items from a list, and are not saved without explicit confirmation such as pressing a `}<strong parentName="p">{`Save`}</strong>{` button.`}</p>
    <p>{`A toggle switch should never be used as a replacement for a checkbox. See `}<a parentName="p" {...{
        "href": "/ui-patterns/saving"
      }}>{`saving patterns`}</a>{` for more details.`}</p>
    <DoDontContainer mdxType="DoDontContainer">
  <Do mdxType="Do">
    <img role="presentation" src="https://user-images.githubusercontent.com/2313998/226448728-73c5ca2d-82c4-4d8a-b043-d200dff3ae16.png" />
    <Caption mdxType="Caption">Use a toggle switch like a button</Caption>
  </Do>
  <Dont mdxType="Dont">
    <img role="presentation" src="https://user-images.githubusercontent.com/2313998/226448729-46e2b1e1-61ed-4773-bdba-29f7c0174052.png" />
    <Caption mdxType="Caption">Don't use a toggle switch as a replacement for a checkbox</Caption>
  </Dont>
    </DoDontContainer>
    <h2>{`Accessibility`}</h2>
    <h3>{`Keyboard navigation`}</h3>
    <p>{`Toggle switches should have the same keyboard navigation features as a button. A toggle switch may be focused using the `}<inlineCode parentName="p">{`Tab`}</inlineCode>{` key, and pressing the `}<inlineCode parentName="p">{`Enter`}</inlineCode>{` or `}<inlineCode parentName="p">{`Space`}</inlineCode>{` key will toggle the switch.`}</p>
    <h3>{`Touch target`}</h3>
    <p>{`When the UI is rendered on a touch-screen device, ensure that the toggle switch has a large enough touch target size. The toggle switch should respond to hovers, clicks, and taps anywhere in the touch target area, even if it isn't directly on the control.`}</p>
    <p><img parentName="p" {...{
        "src": "https://user-images.githubusercontent.com/2313998/226448740-1506ddb5-3cdb-4aef-9a3b-0d66839e7d11.png",
        "alt": "Diagram showing touch target size on medium and small toggle switches"
      }}></img></p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="ToggleSwitch" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      